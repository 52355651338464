body {
  margin: 0;
  font-weight: 400;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none !important;
}

html {
  min-height: 100%;
  background-color: #F0F0F0;
}

strong,
b,
strong *,
b * {
  font-weight: 600;
}

em,
i,
em *,
i * {
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.greyscale {
  filter: grayscale(1);
}

.greyscale:hover {
  filter: none;
}

.WYSIWYG ol {
  list-style: decimal;
  margin-left: 30px;
}

.WYSIWYG ul {
  list-style: disc;
  margin-left: 30px;
}

.MessageList__NotFirstSlide .swiper {
  padding-left: 64px;
  transition: all 0.3s linear;
}

.MessageList__FirstSlide .swiper {
  padding-left: 0;
  transition: all 0.3s linear;
}